import { useState, useEffect, useMemo } from "react";
import MaterialTable from "../../components/MaterialTable";
import { IconButton, Button, Modal, Box, Typography, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";
import dotenv from "react-dotenv";
import withMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";

const CustomerIndexPage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(
    () => [
      {
        id: "name",
        accessorKey: "name",
        header: t("name"),
        size: 170,
        Cell: (cell) => (
          <Box display={"flex"}>
            <Avatar
              alt="avatar"
              src={cell.row.original?.avatar?.url}
              sx={{
                width: "50px",
                height: "50px",
                border: 4,
                backgroundColor: "#D9D9D9",
                borderColor: "white",
              }}
            />
            <Box sx={{ mt: 2 }}>{cell.row.original.name}</Box>
          </Box>
        ),
      },
      {
        id: "business_name",
        accessorKey: "business_name",
        header: t("business_name"),
        key: "business_name",
        size: 130,
      },
      {
        id: "business_email",
        accessorKey: "business_email",
        header: t("email"),
        size: 150,
      },
      {
        id: "contact-name",
        accessorKey: "contact.fullname",
        header: t("reference_contact"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 150,
      },
      {
        id: "phone",
        size: 100,
        accessorKey: "phones.0.number",
        header: t("phone"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "institutions",
        size: 150,
        Cell: (cell) => {
          if (
            cell.row.original.institutions &&
            cell.row.original.institutions.length > 0
          ) {
            return cell.row.original.institutions
              .map((institution) => institution.name)
              .join(", ");
          }
        },
        header: t("institutions"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "patology",
        accessorKey: "patology",
        header: t("patology"),
        Cell: () => <span>ICC</span>,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        size: 80,
      },
      {
        id: "test_patients",
        size: 150,
        Cell: (cell) => {
          if (
            cell.row.original.test_patients &&
            cell.row.original.test_patients.length > 0
          ) {
            return cell.row.original.test_patients.length
          }
        },
        header: t("test_patients_amount"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "business_model",
        size: 150,
        Cell: (cell) => {
          if (
            cell.row.original.business_model &&
            cell.row.original.business_model > 0
          ) {
            return cell.row.original.business_model
          }
        },
        header: t("business_model"),
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        id: "actions",
        accessorKey: "actions",
        header: t("actions"),
        size: 150,
        enableColumnActions: false,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: (cell) =>
          ["superadmin", "admin-vs"].some(
            r => props.role.includes(r)
          ) ? (
            <>
              <IconButton
                className="survey-action"
                onClick={() =>
                  navigate(`/customers/${cell.row.original["id"]}`, {
                    state: {
                      template: cell.row.original.title,
                    },
                  })
                }
              >
                <img src="/edit.png" alt="view" />
              </IconButton>

              <IconButton
                aria-label="view"
                onClick={() => {
                  setIdDelete(cell.row.original);
                }}
              >
                <img src="/delete.png" alt="view" />
              </IconButton>

              <Button
                variant="contained"
                endIcon={<img src={"/useradd.png"} alt={"administrativos"} />}
                sx={{
                  width: "55%",
                  fontSize: "12px",
                  p: 1,
                  bgcolor: "#3988E3",
                  color: "#FCFCFC",
                }}
                onClick={() =>
                  navigate(`/customers/${cell.row.original.id}/admins`, {
                    state: {
                      customer: cell.row.original.name,
                    },
                  })
                }
              >
                {t("admin")}
              </Button>
            </>
          ) : (
            <></>
          ),
      },
    ],
    [navigate, t]
  );
  const handleClose = () => setOpenDeleteModal(false);

  const setIdDelete = (customer) => {
    setCustomerToDelete(customer);
    setOpenDeleteModal(true);
  };

  const deleteCustomer = () => {
    setIsLoading(true);
    axios
      .delete(
        new URL(
          `/institutions/v1/customers/` + customerToDelete.id,
          dotenv.API_URL
        ).href,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() =>
        props.showMessage(t("agrup_deleted_successfully"), () => getCustomers())
      )
      .catch((error) =>
        props.showMessage(error.response.data.message, () => {}, "error")
      )
      .finally(() => {
        setIsLoading(false);
        setOpenDeleteModal(false);
      });
  };

  useEffect(() => {
    getCustomers();
  }, [columnFilters, globalFilter, pagination, sorting, user]);

  const getCustomers = () => {
    const fetchData = async () => {
      setIsLoading(true);
      setIsRefetching(true);
      setIsError(false);

      const url = new URL("/institutions/v1/customers", dotenv.API_URL);
      url.searchParams.set("page", `${pagination.pageIndex + 1}`);
      url.searchParams.set("size", `${pagination.pageSize}`);
      url.searchParams.set("filters", JSON.stringify(columnFilters ?? []));
      url.searchParams.set("globalFilter", globalFilter ?? "");
      url.searchParams.set("sorting", JSON.stringify(sorting ?? []));

      axios
        .get(url.href, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          setData(response.data.data);
          setRowCount(response.data.total);
          setIsError(false);
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        });
    };
    fetchData();
  };

  const styles = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      borderRadius: "16px",
      boxShadow: 24,
      p: 4,
    },
  };

  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        title="Institutions"
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: t("error_fetching_data"),
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
      <br />
      <Button
        variant="contained"
        onClick={() => navigate("/customers/add")}
        color="success"
      >
        {t("enroll_institution_grouper")}
      </Button>
      <Modal open={openDeleteModal} onClose={handleClose}>
        <Box sx={styles.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("delete_doctor")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
           {t("are_you_sure_delete")} {customerToDelete?.name} {" "} {t("the_medical_group")}
          </Typography>
          <Box display={"flex"} gap={"10px"} mt={"10px"}>
            <Button
              sx={[{ background: "#5EC360", color: "white" }]}
              onClick={deleteCustomer}
            >
              {t("yes")}
            </Button>
            <Button
              sx={[{ background: "#D04444", color: "white" }]}
              onClick={() => setOpenDeleteModal(false)}
            >
              {t("no")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default withMessages(CustomerIndexPage);
