import { Box } from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import { useAuth } from "../../../hooks/useAuth";
import MaterialTable from "../../../components/MaterialTable";
import { useTranslation } from "react-i18next";
import axios from "axios";
import dotenv from "react-dotenv";
import moment from "moment";

const HistoricalNutrition = (props) => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [isError, setIsError] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowSelection, setRowSelection] = useState({});
  const tableInstanceRef = useState({});
  const [data, setData] = useState({});

  const columns = useMemo(() => [
    {
      id: "nutrition_image_url",
      enableColumnActions: false,
      size: 1,
      grow: false,
      Cell: ({ cell }) => (
        <Box display="flex" alignItems="center" justifyContent="center">
          <img
            src={cell.row.original.image}
            alt="icon"
            style={{ width: 83, height: 83, marginRight: 8, borderRadius: 17 }}
          />
        </Box>
      ),
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: t("date_time"),
      size: 50,
      Cell: (cell) => (
        <>
          <p>
            <span>
              <b>{moment(cell.row.original.datetime).format("DD/M/yy")}</b>
            </span>
            <span> {moment(cell.row.original.datetime).format("HH:mm")}</span>
          </p>
        </>
      ),
    },
    {
      id: "ingredients",
      accessorKey: "ingredients",
      header: t("ingredients"),
      size: 180,
      Cell: (cell) => (
        <>
          {cell.row.original.ingredients.map((ingredient) => (
            <Box>{ingredient.food_name}</Box>
          ))}
        </>
      ),
    },
    {
      id: "composition",
      accessorKey: "composition",
      header: t("composition"),
      size: 180,
      Cell: (cell) => (
        <>
          {cell.row.original.ingredients.map((ingredient) => (
            <Box>
              {ingredient.g_equivalent ? `${ingredient.g_equivalent} g` : <></>}
              {ingredient.ml_equivalent ? (
                `${ingredient.gml_equivalent} ml`
              ) : (
                <></>
              )}
            </Box>
          ))}
        </>
      ),
    },
  ]);

  const styles = {
    boxContainer: {
      marginTop: "10px",
      borderRadius: "18px",
      backgroundColor: "#F9FAFB",
      width: "100%",
      padding: "10px",
      color: "background: #FCFCFC",
    },
    boxTitle: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "24px",
      letterSpacing: "0.01em",
      textAlign: "left",
      padding: "10px",
      color: "#737589",
    },
  };

  useEffect(() => {
    setIsLoading(true);
    setIsRefetching(true);
    const url = new URL(
      `/institutions/v1/patients/${props.patient}/nutrition`,
      dotenv.API_URL
    );
    url.searchParams.set("page", `${pagination.pageIndex + 1}`);
    axios
      .get(url.href, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        if (response.headers.get("content-type") == "application/json") {
          setData(response.data);
          setRowCount(response.total);
        }
      })
      .catch(() => setIsError(true))
      .finally(() => {
        setIsLoading(false);
        setIsRefetching(false);
      });
  }, [pagination]);

  return (
    <Box sx={styles.boxContainer}>
      <Box sx={styles.boxTitle}>{t("historical_nutrition")}</Box>
      <MaterialTable
        columns={columns}
        data={data}
        title={t("historical_nutrition")}
        enableRowSelection={false}
        onRowSelectionChange={setRowSelection}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: t("error_fetching_data"),
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        onGlobalFilterChange={setGlobalFilter}
        rowCount={rowCount}
        tableInstanceRef={tableInstanceRef}
        state={{
          rowSelection,
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
        }}
      />
    </Box>
  );
};
export default HistoricalNutrition;
