import { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Select,
  Typography,
  Modal,
  Checkbox,
  FormControlLabel,
  Paper,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import Grid from "@mui/material/Unstable_Grid2";
import EnrollDoctorModal from "../../components/EnrollDoctorModal";
import axios from "axios";
import dotenv from "react-dotenv";
import { TextField, SelectSx } from "../../theme/vs-theme";
import WithMessages from "../../components/WithMessages";
import { useTranslation } from "react-i18next";
import moment from "moment";
import EditPriceComponent from "./components/EditPriceComponent";

const MedicalGroupsUpdatePage = (props) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [customerId, setCustomerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [previewSrc, setPreviewSrc] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState({});
  const [errors, setErrors] = useState({});
  const [institutions, setInstitutions] = useState([]);
  const [pathologies, setPathologies] = useState([]);
  const [code, setCode] = useState("");
  const [accessFee, setAccessFee] = useState("");
  const [form, setForm] = useState({
    name: "",
    pathology_id: null,
    users: [],
    institution_id: null,
    fake: 0,
    // price: 0,
    // created_by_user_id: null,
  });
  const location = useLocation();
  const [plans, setPlans] = useState([]);
  const [businessModel, setBusinessModel] = useState({});

  useEffect(() => {
    if (location.state?.medicalGroup) {
      props.setTitle(["Equipos Médicos", location.state.medicalGroup]);
    }
  }, []);

  const { id } = useParams();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [invitations, setInvitations] = useState([]);

  const handleChange = (event) => {
    if (errors[event.target.name]) {
      setErrors({ ...errors, [event.target.name]: null });
    }
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  /*
  const getBusinessModel = () => {
    if (!customerId) return;
    const url = new URL(
      `/institutions/v1/payments/customer/check-model/${customerId}`,
      dotenv.API_URL
    );
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setBusinessModel(response.data.type_model ?? 0);
      })
      .catch(() => {
        props.showMessage(
          t("error cargando modelo de negocio"),
          () => console.log(errors),
          "error"
        );
      });
  };

  const getFees = () => {
    let planPrice = "0";
    if (plans.constructor == Array) {
      planPrice =
        plans.length > 0
          ? plans.find((plan) => plan.pathology_id == form.pathology_id).price
          : "0";
    } else {
      planPrice = plans.price;
    }
    setForm({ ...form, price: planPrice });
  };

  const fetchAccessFee = () => {
    if (!(customerId && form.pathology_id)) return;
    const url = new URL(
      `/institutions/v1/payments/plan-prices/${customerId}/${form.pathology_id}`,
      dotenv.API_URL
    );
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        response.data
          ? setAccessFee(+response.data.price)
          : props.showMessage(
              t("not_fetch_prices"),
              () => {
                console.log(errors);
              },
              "error"
            );
      })
      .catch(() => {
        props.showMessage(
          t("not_fetch_prices"),
          () => console.log(errors),
          "error"
        );
      });
  };

  useEffect(() => {
    fetchAccessFee();
    getFees();
  }, [form.pathology_id, customerId]);

  useEffect(() => {
    getBusinessModel();
  }, [customerId]);
  */

  const handleFakeCheckChange = (event) => {
    setChecked(event.target.checked);
    let formValue = event.target.checked ? 1 : 0;
    setForm({ ...form, [event.target.name]: formValue });
  };

  const setAvatar = (event) => {
    const file = event.target.files[0];
    if (file) {
      setErrors({ ...errors, avatar: null });
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewSrc(reader.result);
      };
      reader.readAsDataURL(file);
      setForm({ ...form, avatar: file });
    }
  };

  const saveMedicalGroup = () => {
    setLoading(true);
    axios
      .post(
        new URL(
          `/institutions/v1/medical-groups/${id}?_method=PUT`,
          dotenv.API_URL
        ).href,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() =>
        props.showMessage(t("medical_group_edit"), () =>
          navigate("/medical-groups")
        )
      )
      .catch((error) => {
        if (error.response.status === 422) {
          setErrors(error.response.data?.errors);
        } else {
          props.showMessage(
            t("we_cannot_edit_equipe") + error.response.data?.message,
            () => navigate("/medical-groups"),
            "error"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMedicalGroup();
  }, [user, id, navigate, props, pathologies]);

  const getMedicalGroup = () => {
    setLoading(true);
    if (user?.token && pathologies.length) {
      const url = new URL(
        "/institutions/v1/medical-groups/" + id,
        dotenv.API_URL
      );
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((response) => {
          let fakeValue = response.data.fake ? 1 : 0;
          // setPlans(response.data.plans);
          setForm({
            ...form,
            name: response.data.name,
            pathology_id: response.data.pathology_id,
            users: response.data.users,
            institution_id: response.data.institution_id,
            fake: fakeValue,
            price: 0,
            created_by_user_id: response.data.plans
              ? response.data.plans.created_by_user_id
              : user.user.id,
          });
          response.data.fake == 1 ? setChecked(true) : setChecked(false);
          setCode(response.data.code);
          setInvitations(response.data.invitations);
          setPreviewSrc(response.data?.avatar?.url);
          setCustomerId(response.data.institution.customer_id);
        })
        .catch((error) => {
          props.showMessage(t("not_loaded_me"), () =>
            navigate("/medical-groups")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (user.user.institutions.length || user.user.customers.length) {
      const institutionArr = [];
      user.user.institutions.forEach((institutionItem) => {
        institutionArr.push({
          id: institutionItem.id,
          name: institutionItem.name,
        });
      });

      user.user.customers.forEach((customer) => {
        customer.institutions.forEach((institutionItem) => {
          institutionArr.push({
            id: institutionItem.id,
            name: institutionItem.name,
          });
        });
      });
      const uniqueInstitutions = institutionArr.filter(
        (institution, index, self) =>
          index === self.findIndex((t) => t.id === institution.id)
      );
      setInstitutions(uniqueInstitutions);
    }
  };

  useEffect(() => {
    axios
      .get(new URL("/institutions/v1/pathologies", dotenv.API_URL), {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => {
        setPathologies(response.data);
      })
      .catch(() => {
        props.showMessage(t("cannot_loaded_patologyes"), () =>
          navigate("/medical-groups")
        );
      });
  }, [user, navigate]);

  const deleteInvitation = (invitationId) => {
    axios
      .delete(
        new URL("/institutions/v1/invitations/" + invitationId, dotenv.API_URL),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        props.showMessage(t("deleted_patologies_correctly"), () => {
          getMedicalGroup();
        });
      })
      .catch(() => {
        props.showMessage(
          t("we_cant_deleted_invitation_correctly_try_again"),
          () => {},
          "error"
        );
      });
  };

  const deleteAllInvitations = () => {
    axios
      .delete(
        new URL(
          "/institutions/v1/medical-groups/" + id + "/invitations",
          dotenv.API_URL
        ),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then(() => {
        props.showMessage(t("deleted_invitations_correctly"), () => {
          getMedicalGroup();
        });
      })
      .catch(() => {
        props.showMessage(
          t("we_cant_deleted_invitations_correctly_try_again_later"),
          () => {},
          "error"
        );
      });
  };

  const resendAllInvitations = () => {
    axios
      .post(
        new URL(
          "/institutions/v1/medical-groups/" + id + "/invitations/resend",
          dotenv.API_URL
        ),
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        props.showMessage(response.data.message);
      })
      .catch(() => {
        props.showMessage(
          t("cannot_resend_invitations_tray_again_later"),
          () => {},
          "error"
        );
      });
  };

  const resendInvitation = (invitationId) => {
    axios
      .post(
        new URL(
          "/institutions/v1/invitations/" + invitationId + "/resend",
          dotenv.API_URL
        ),
        undefined,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        props.showMessage(response.data.message);
      })
      .catch(() => {
        props.showMessage(
          t("cannot_resend_invitation_tray_leter"),
          () => {},
          "error"
        );
      });
  };

  const handleDelete = (user) => {
    setOpenDeleteModal(true);
    setUserToDelete(user);
  };

  const deleteDoctor = () => {
    setOpenDeleteModal(false);
    axios
      .delete(
        new URL(
          "/institutions/v1/medical-groups/" + id + "/" + userToDelete?.id,
          dotenv.API_URL
        ),
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + user.token,
          },
        }
      )
      .then((response) => {
        props.showMessage(response.data);
      })
      .catch(() => {
        props.showMessage(t("cannot_deleted_doctor"), () => {}, "error");
      });
  };

  const styles = {
    buttons: {
      borderRadius: "15px",
      color: "#FCFCFC",
      fontSize: "12px",
      fontWeight: "400",
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      borderRadius: "15px",
      boxShadow: 24,
      p: 4,
    },
  };

  return (
    <div>
      <Grid container>
        <Grid>
          <Avatar
            alt="avatar"
            src={previewSrc}
            sx={{
              width: "80px",
              height: "80px",
              border: 4,
              backgroundColor: "#D9D9D9",
              borderColor: "white",
            }}
          />
        </Grid>
        <Grid item xs={2} sx={{ alignContent: "center" }}>
          <label htmlFor="avatar-file">
            <input
              name="avatar"
              onClick={(event) => {
                event.target.value = null;
              }}
              onInput={setAvatar}
              accept="image/*"
              id="avatar-file"
              type="file"
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              component="span"
              color="primary"
              endIcon={<img src={"/plus.png"} alt={"Avatar"} />}
              sx={{ width: 200, ml: 3 }}
            >
              {t("add_photo")}
            </Button>
          </label>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <Paper sx={{ backgroundColor: "white", padding: 4 }} elevation={0}>
          <Grid container spacing={4}>
            <Grid item xs={4}>
              <FormControl variant="standard" required>
                <InputLabel shrink htmlFor="name-input">
                  {t("name")}
                </InputLabel>
                <TextField
                  id="name-input"
                  name="name"
                  value={form.name}
                  onChange={handleChange}
                />
                <FormHelperText
                  id="name-input-error-text"
                  sx={{ color: "red" }}
                >
                  {errors?.name && errors.name[0]}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel shrink htmlFor="institution-id-input">
                  {t("intitution")}
                </InputLabel>
                <Select
                  displayEmpty
                  sx={SelectSx}
                  id="institution-id-input"
                  name="institution_id"
                  value={form.institution_id}
                  onChange={handleChange}
                >
                  {institutions.map((institution) => (
                    <MenuItem value={institution.id} key={institution.id}>
                      {institution.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl>
                <InputLabel shrink htmlFor="patology-input">
                  {t("patologies")}
                </InputLabel>
                <Select
                  displayEmpty
                  sx={SelectSx}
                  id="patology-input"
                  name="pathology_id"
                  value={form.pathology_id}
                  onChange={handleChange}
                  defaultValue={form.pathology_id}
                >
                  {pathologies.map((pathology) => (
                    <MenuItem value={pathology.id} key={pathology.id}>
                      {pathology.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl>
                <InputLabel shrink htmlFor="code-input">
                  {t("medical_code")}
                </InputLabel>
                <TextField id="code-input" disabled value={code} />
              </FormControl>
            </Grid>
          </Grid>
          {/*businessModel == 1 ? (
            <EditPriceComponent
              form={form}
              setForm={setForm}
              accessFee={accessFee}
            />
          ) : (
            <></>
          )*/}
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                required
                sx={{
                  minHeight: 10,
                  backgroundColor: "#F6F7FA",
                  borderRadius: "12px",
                  border: "1px solid #869AE8",
                  padding: "10px 12px",
                  color: "#869AE8",
                  "label + &": {
                    marginTop: 3,
                  },
                }}
              >
                <InputLabel shrink htmlFor="description-input" sx={{ m: 1 }}>
                  {t("medical_asoc")}
                </InputLabel>
                <Grid
                  direction="row"
                  justifyContent="flex-start"
                  spacing={4}
                  sx={{ mt: 1 }}
                >
                  {form.users?.map((user, index) => {
                    return (
                      <Chip
                        key={index}
                        avatar={<Avatar alt="avatar" src={user.avatar?.url} />}
                        sx={{ m: 1 }}
                        label={user.name + " " + user.lastname}
                        variant="outlined"
                        onDelete={() => {
                          handleDelete(user);
                        }}
                      />
                    );
                  })}
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<img src={"/plus.png"} alt={"Avatar"} />}
                    onClick={() => setOpen(true)}
                    sx={{ width: 200, ml: 3, height: 40 }}
                  >
                    {t("add_medical")}
                  </Button>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Paper
              sx={{
                borderRadius: "12px 12px 0px 0px",
                background: "rgba(227, 143, 143, 0.15)",
                border: "0.97px solid #EABCBC",
                padding: 2,
                width: "100%",
              }}
              elevation={0}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography color="#D04444" sx={{ p: 0 }}>
                  {t("pending_doctors_accept")}
                </Typography>
                <Box sx={{ display: "flex", gap: "6px" }}>
                  <Button
                    sx={[{ background: "#5EC360" }, styles.buttons]}
                    endIcon={<img src="/send_white.png" />}
                    onClick={() => resendAllInvitations()}
                  >
                    {t("resend_invitations")}
                  </Button>
                  <Button
                    sx={[{ background: "#D04444" }, styles.buttons]}
                    endIcon={<img src="/delete_white.png" />}
                    onClick={() => deleteAllInvitations()}
                  >
                    {t("remuve_invitations")}
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TableContainer
                elevation={0}
                component={Paper}
                sx={{
                  mt: 1,
                  minHeight: 10,
                  backgroundColor: "rgba(227, 143, 143, 0.15)",
                  borderRadius: "0px 0px 12px 12px",
                  border: "0.97px solid #EABCBC",
                  padding: "10px 12px",
                  color: "#869AE8",
                  "label + &": {
                    marginTop: 3,
                  },
                }}
              >
                <Table
                  sx={{ minWidth: 650, border: 0, color: "#D04444" }}
                  aria-label="pending invitations table"
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": { color: "#D04444", pl: 0, fontWeight: "bold" },
                      }}
                    >
                      <TableCell>{t("doctor_email")}</TableCell>
                      <TableCell align="center">
                        {t("date_send_link")}
                      </TableCell>
                      <TableCell align="center">{t("actions")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invitations.map((invitation) => (
                      <TableRow
                        key={invitation.id}
                        sx={{
                          "& td": { color: "#D04444", p: 0 },
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell scope="row">{invitation.email}</TableCell>
                        <TableCell align="center">
                          {moment(invitation.created_at).format("lll")}
                        </TableCell>
                        <TableCell align="center">
                          <Box display={"flex"} gap={"6px"} padding={"5px"}>
                            <Button
                              sx={[{ background: "#5EC360" }, styles.buttons]}
                              endIcon={<img src="/send_white.png" />}
                              onClick={() => resendInvitation(invitation.id)}
                            >
                              {t("resend_invitation")}
                            </Button>
                            <Button
                              sx={[{ background: "#3657D9" }, styles.buttons]}
                              endIcon={<img src="/copy.png" />}
                              onClick={() => {
                                props.showMessage(
                                  "URL copiada a portapapeles",
                                  () => {
                                    navigator.clipboard.writeText(
                                      invitation.enroll_secure_link
                                    );
                                  }
                                );
                              }}
                            >
                              {t("copi_link_invitatio")}
                            </Button>
                            <Button
                              sx={[{ background: "#D04444" }, styles.buttons]}
                              endIcon={<img src="/delete_white.png" />}
                              onClick={() => deleteInvitation(invitation.id)}
                            >
                              {t("remuve_invitation")}
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            margin={"10px 0"}
          >
            <FormControlLabel
              name="fake"
              label={t("test_medical_group")}
              checked={checked}
              value={form.fake}
              onChange={handleFakeCheckChange}
              control={<Checkbox name="fake" />}
            />
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" spacing={4}>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="error"
                onClick={() => navigate("/medical-groups")}
              >
                {t("cancel")}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="success"
                onClick={saveMedicalGroup}
                disabled={loading}
              >
                {t("save")}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Backdrop
        sx={{ open: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EnrollDoctorModal
        showMessage={props.showMessage}
        open={open}
        onClose={handleClose}
        medicalGroupId={id}
        institutionId={user.user?.institutions[0]?.id}
      />
      <Modal open={openDeleteModal} onClose={handleClose}>
        <Box sx={styles.modal}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("delete_doctor")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t("are_you_sure_delete")} {userToDelete?.name}{" "}
            {userToDelete?.lastname} {t("the_medical_group")}
          </Typography>
          <Box display={"flex"} gap={"10px"} mt={"10px"}>
            <Button
              sx={[{ background: "#5EC360" }, styles.buttons]}
              onClick={deleteDoctor}
            >
              {t("yes")}
            </Button>
            <Button
              sx={[{ background: "#D04444" }, styles.buttons]}
              onClick={() => setOpenDeleteModal(false)}
            >
              {t("no")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default WithMessages(MedicalGroupsUpdatePage);
